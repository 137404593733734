import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, radius, fontSizes, colors, fontWeights } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import Button from 'components/Button'

import imageBackgroundHeader from 'static/images/affiliate_marketing/header_background_picture.webp'
import imageHeader from 'static/images/affiliate_marketing/header_picture.webp'

import imageEmpowerYourAudience from 'static/images/affiliate_marketing/image_empower_your_audience.webp'
import imageWhyPartner from 'static/images/affiliate_marketing/image_why_partner.webp'
import imageWhyPartnerDollar from 'static/images/affiliate_marketing/image_dollar.svg'
import imageWhyPartnerSettings from 'static/images/affiliate_marketing/image_settings.svg'
import imageWhyPartnerPerson from 'static/images/affiliate_marketing/image_person.svg'

const WrapperHeader = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background-image: url('${imageBackgroundHeader}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: ${({ bg }) => bg || COLOR_CONSTANTS.WHITE};
`

const StyledReversedButton = styled(Button.Reversed)`
  box-shadow: 0px 18px 24px -8px rgba(216, 233, 255, 0.32);
  width: 215px;
  height: 100%;
  font-size: ${fontSizes.m};
  font-weight: bold;
`

const StyledRegisterWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: radial-gradient(50% 81.39% at 50% 50%, #0e6ce7 0%, #005ed7 46.67%, #0054c0 100%);
  overflow: hidden;
`

const BorderedBox = styled(Box)`
  border-radius: ${radius.pill};
  z-index: 0;
  position: absolute;
  border: 1px solid ${({ amount }) => transparentize(1 - amount, colors.white)};
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledStepItem = styled(Flex)`
  background: ${colors.primary};
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  font-size: ${fontSizes.xl};
  font-weight: ${fontWeights.bold};
  color: ${colors.white};
  width: 40px;
  height: 40px;
  min-width: 40px;
`

const StyledStepLine = styled(Flex)`
  background: ${colors.primary};
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 26.5vw;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledRegisterLink = styled(Text)`
  cursor: pointer;
  color: ${colors.primary};
  text-decoration: underline;
`

const EMPOWER_YOUR_AUDIENCE_OPTIONS = [
  {
    title: 'Boost Your Income',
    description:
      'Looking for a simple, rewarding way to monetize your content and provide your audience with a top-tier social media management tool? Join the Vista Social Affiliate Program and turn your influence into income.',
  },
  {
    title: 'Rewarding You for Sharing',
    description:
      'We love our customers, and when they love us back, it’s even better. That’s why we created the Vista Social Affiliate Program—to reward you for sharing the love and earning as you go!',
  },
]

const WHY_PARTNER_OPTIONS = [
  {
    image: imageWhyPartnerDollar,
    title: 'Generous Commissions',
    description: `Earn up to 20% recurring commissions for every new customer you bring to Vista Social. Your success is our success, and we’re committed to rewarding you for it. Plus, the users that you refer will also receive a 10% off their first year! It's a sweet deal.`,
  },
  {
    image: imageWhyPartnerSettings,
    title: 'Top-Tier Tools',
    description:
      'Promote a leading social media management platform trusted by thousands of marketers, agencies, and businesses worldwide. From scheduling and analytics to engagement and employee advocacy, Vista Social is a comprehensive solution that drives results.',
  },
  {
    image: imageWhyPartnerPerson,
    title: 'Personalized Support',
    description:
      'We’re here to help you succeed. From onboarding guidance to tailored marketing assets, we provide everything you need to start earning quickly.',
  },
]

const REGISTER_TODAY_LINK = 'https://vistasocial.firstpromoter.com/'

const AffiliateMarketing = () => {
  return (
    <App fullHeader>
      <SEO
        title="Join the Affiliate Marketing Program"
        description="Register today & get a recurring 20% commission!*"
        path="/affiliate-marketing/"
      />

      <WrapperHeader>
        <Container
          pl={{ mobile: 'm', tablet: 'l' }}
          pr={{ mobile: 'm', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              py="l"
              mb={{ mobile: 0, desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color="white"
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Join the Affiliate
                        <br /> Marketing Program
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pr={{ mobile: 0, desktop: 'l' }}
                      color="white"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Register today & get a recurring 20% commission!*
                    </H2>
                  </Flex>

                  <Flex
                    mt="l"
                    pt="m"
                    pb={{ mobile: 'm', tablet: '0' }}
                    justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                  >
                    <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                      <StyledReversedButton as="a" href={REGISTER_TODAY_LINK}>
                        Register Today
                      </StyledReversedButton>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="478px"
                  alt="Join the Affiliate Marketing Program"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </WrapperHeader>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Partner with{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Vista Social{' '}
              </H1>
              – Earn Big, Empower Your Audience
            </H3>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} maxWidth="616px" width="100%">
              <LocalImage
                src={imageEmpowerYourAudience}
                width="100%"
                alt="Partner with Vista Social – Earn Big, Empower Your Audience"
              />
            </Flex>

            <Flex
              pt="xl"
              pb="xl"
              mt={{ mobile: 0, tablet: 'l', desktop: 0 }}
              ml={{ mobile: 0, tablet: 'l' }}
              flexDirection="column"
              justifyContent="space-between"
            >
              <Grid gridGap="l" gridTemplateColumns="repeat(1, 1fr)" maxWidth={{ mobile: '100%', desktop: '445px' }}>
                <Flex flexDirection="column">
                  <Text textAlign="left" fontSize="xxl" color={COLOR_CONSTANTS.DENIM} fontWeight="bold">
                    Welcome to Vista Social
                  </Text>

                  <Text mt="m" textAlign="left" fontSize="m" color="secondaryText">
                    We're the all-in-one social media management platform designed to deliver unbeatable value to
                    marketing agencies, freelancers, and social media managers.
                    <br />
                    <br />
                    Our platform sets the standard for collaboration and efficiency in social media marketing.
                  </Text>
                </Flex>
                {EMPOWER_YOUR_AUDIENCE_OPTIONS.map(({ title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%">
                    <Flex alignItems="center">
                      <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                      <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pb={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Why Partner with{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Vista Social
            </H1>
          </H3>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} order={{ mobile: 2, desktop: 2 }} maxWidth="744.5px" width="100%">
              <LocalImage src={imageWhyPartner} width="100%" alt="Why Partner with Vista Social" />
            </Flex>
            <Flex order={{ mobile: 1, desktop: 1 }}>
              <Grid gridGap="l" gridTemplateColumns="repeat(1, 1fr)" maxWidth={{ mobile: '100%', desktop: '420px' }}>
                {WHY_PARTNER_OPTIONS.map(({ image, title, description }) => (
                  <Flex flexDirection="column" key={title} width="100%">
                    <Flex alignItems="center">
                      <LocalImage src={image} width="44px" height="44px" alt={title} />
                      <Text ml="m" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="l">
                        {title}
                      </Text>
                    </Flex>
                    <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                ))}
              </Grid>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', desktop: '0' }}
          pr={{ mobile: 'l', desktop: '0' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth="1100px"
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            How It Works
          </H3>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection="column"
            alignItems="center"
          >
            <Grid mt="m" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', desktop: 'repeat(3, 1fr)' }} gridGap="40px">
              <Flex
                flexDirection={{ mobile: 'row', desktop: 'column' }}
                alignItems={{ mobile: 'baseline', desktop: 'center' }}
              >
                <StyledStepItem>1</StyledStepItem>

                <Flex flexDirection="column" ml={{ mobile: 'm', desktop: 0 }}>
                  <Text
                    mt={{ mobile: 0, desktop: 'm' }}
                    fontSize="xxl"
                    color={COLOR_CONSTANTS.DENIM}
                    fontWeight="bold"
                    textAlign={{ mobile: 'left', desktop: 'center' }}
                  >
                    Sign Up
                  </Text>

                  <Text mt="s" fontSize="m" textAlign={{ mobile: 'left', desktop: 'center' }}>
                    It’s easy and free!{' '}
                    <StyledRegisterLink as="a" href={REGISTER_TODAY_LINK} fontSize="m">
                      Simply complete our quick registration
                    </StyledRegisterLink>{' '}
                    process and gain instant access to your unique affiliate link.
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flexDirection={{ mobile: 'row', desktop: 'column' }}
                alignItems={{ mobile: 'baseline', desktop: 'center' }}
              >
                <StyledStepItem position="relative">
                  <StyledStepLine right="3em" display={{ mobile: 'none', desktop: 'flex' }} />
                  2
                  <StyledStepLine left="3em" display={{ mobile: 'none', desktop: 'flex' }} />
                </StyledStepItem>

                <Flex flexDirection="column" ml={{ mobile: 'm', desktop: 0 }}>
                  <Text
                    mt={{ mobile: 0, desktop: 'm' }}
                    fontSize="xxl"
                    color={COLOR_CONSTANTS.DENIM}
                    fontWeight="bold"
                    textAlign={{ mobile: 'left', desktop: 'center' }}
                  >
                    Promote
                  </Text>

                  <Text mt="s" fontSize="m" textAlign={{ mobile: 'left', desktop: 'center' }}>
                    Share Vista Social with your network using customizable marketing materials, banners, and content
                    ideas we provide.
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flexDirection={{ mobile: 'row', desktop: 'column' }}
                alignItems={{ mobile: 'baseline', desktop: 'center' }}
              >
                <StyledStepItem>3</StyledStepItem>
                <Flex flexDirection="column" ml={{ mobile: 'm', desktop: 0 }}>
                  <Text
                    mt={{ mobile: 0, desktop: 'm' }}
                    fontSize="xxl"
                    color={COLOR_CONSTANTS.DENIM}
                    fontWeight="bold"
                    textAlign={{ mobile: 'left', desktop: 'center' }}
                  >
                    Earn
                  </Text>

                  <Text mt="s" fontSize="m" textAlign={{ mobile: 'left', desktop: 'center' }}>
                    Earn 20% recurring commission for up to 12 months* per referral. Track everything easily in your dashboard!
                  </Text>
                </Flex>
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <StyledRegisterWrapper>
        <BorderedBox width="789px" height="791px" amount={0.1} />
        <BorderedBox width="931px" height="933px" amount={0.2} />
        <BorderedBox width="1107px" height="1109px" amount={0.27} />
        <BorderedBox width="1309px" height="1310px" amount={0.35} />
        <BorderedBox width="1543px" height="1546px" amount={0.42} />
        <BorderedBox width="1839px" height="1842px" amount={0.35} />
        <Container
          my={{ mobile: 'l', tablet: 'xxl' }}
          py={{ mobile: 'l', tablet: 'xl' }}
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" width="100%" zIndex="2">
            <Flex alignItems="center" flexDirection="column" position="relative">
              <Flex>
                <Text fontWeight="extraBold" fontSize="3xl" color="white">
                  Become a Vista Social Affiliate today!
                </Text>
              </Flex>

              <Flex
                mt="l"
                pt="m"
                pb={{ mobile: 'm', tablet: '0' }}
                justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
              >
                <Box height={{ mobile: pxToRem(56), tablet: pxToRem(44) }}>
                  <StyledReversedButton as="a" href={REGISTER_TODAY_LINK}>
                    Register Now
                  </StyledReversedButton>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </StyledRegisterWrapper>

      <Footer />
    </App>
  )
}

export default AffiliateMarketing
